import { getGlazingType, Solution, SOLUTIONS } from '@energiebespaarders/constants';
import { useIsMounted } from '@energiebespaarders/hooks';
import { Box, BoxClass, Flex } from '@energiebespaarders/symbols';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import FlipMove from 'react-flip-move';
import { useCookie } from 'react-use';
import useGAEvent from '~/hooks/useGAEvent';
import { resultsCards_Userless_findUserlessHouse } from '~/types/generated/resultsCards_Userless';
import { resultsCardsStandalone_house } from '~/types/generated/resultsCardsStandalone';
import { resultsPage_house, resultsPage_house_estimates } from '~/types/generated/resultsPage';
import { parseSolutionEstimatesForGTM } from '~/utils/analytics';
import ResultCard from './ResultCard';
import ResultSorter from './ResultSorter';
import { getResultsCardTexts, sortEstimates, SortingProperty } from './utils';

interface ResultCardsProps {
  activeSolutions: Solution[];
  currentEnergyIndex: number;
  hideCheckboxes?: boolean;
  house: resultsPage_house | resultsCardsStandalone_house | resultsCards_Userless_findUserlessHouse;
  setActiveSolutions: Dispatch<SetStateAction<Solution[]>>;
  toggleActiveSolutionsCallback: (solution: Solution, overrideState?: boolean) => void;
  showEnergyLabelPrediction?: boolean;
}

/** Filtered out for otherwise (near-)duplicate estimates – and/or solutions we don't actually have any partners for atm */
export const ignoredSolutions: Solution[] = [
  Solution.CrawlspaceInsulation,
  Solution.AtticFloorInsulation,
  Solution.WindowFrames,
  Solution.Miscellaneous, // not intended to be shown in results overview
  Solution.Sedum, // not intended to be shown in results overview
  Solution.ChargingBox, // not intended to be shown in results overview
  Solution.HomeBattery, // not intended to be shown in results overview
  Solution.UnderfloorHeating, // not intended to be shown in results overview
];

const ResultCards: React.FC<ResultCardsProps> = ({
  activeSolutions,
  children,
  currentEnergyIndex,
  house,
  hideCheckboxes,
  setActiveSolutions,
  toggleActiveSolutionsCallback,
  showEnergyLabelPrediction,
}) => {
  const userMotivation =
    house.__typename !== 'UserlessHouse'
      ? house.lead?.motivation || house.customer?.motivation
      : undefined;
  const defaultSort = useMemo(() => {
    switch (userMotivation) {
      case 'comfort':
        return SortingProperty.comfort;
      case 'ecological':
        return SortingProperty.emission;
      case 'financial':
      case 'maintenance':
      default:
        return SortingProperty.paybackTime;
    }
  }, [userMotivation]);
  const [sortProp, setSortProp] = useState(defaultSort);

  const resultCardTexts = useMemo(() => getResultsCardTexts(house), [house]);
  const infeasibleSolutions = useMemo(
    () =>
      (Object.keys(resultCardTexts) as Solution[]).filter(
        s =>
          resultCardTexts[s].infeasible ||
          ignoredSolutions.includes(s) ||
          s === Solution.AirToAirHeatPump,
      ),
    [resultCardTexts],
  );
  const feasibleSolutions = SOLUTIONS.filter(s => !infeasibleSolutions.includes(s));

  useEffect(() => {
    // set the initial activeSolutions in `Results/index.tsx` to the checked ones based on resultCardTexts
    if (activeSolutions !== feasibleSolutions) {
      setActiveSolutions(feasibleSolutions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infeasibleSolutions]);

  const feasibleEstimates = [...house.estimates].filter(
    e => !infeasibleSolutions.includes(e.solution) && e.energyDelta.money > 0,
  );

  const quickestPayback = useMemo(
    () =>
      sortEstimates([...house.estimates], infeasibleSolutions, SortingProperty.paybackTime)[0]
        .solution,
    [house, infeasibleSolutions],
  );
  const greatestImpact = useMemo(
    () =>
      sortEstimates([...house.estimates], infeasibleSolutions, SortingProperty.emission)[0]
        .solution,
    [house, infeasibleSolutions],
  );

  const mostComfort = useMemo(
    () =>
      sortEstimates([...house.estimates], infeasibleSolutions, SortingProperty.comfort)[0].solution,
    [house, infeasibleSolutions],
  );

  const emissionDeltas = useMemo(
    () =>
      [...(feasibleEstimates || [])]
        .map(estimate => estimate.energyDelta.emission!)
        .sort((a, b) => b - a),
    [feasibleEstimates],
  );
  const getEmissionRating = useCallback(
    (estimate: resultsPage_house_estimates) => {
      const highestEmissionDelta = emissionDeltas[0];
      const emissionRating = Math.ceil((estimate.energyDelta.emission / highestEmissionDelta) * 5);
      return Math.min(5, Math.max(1, emissionRating));
    },
    [emissionDeltas],
  );

  const comfortDeltas = useMemo(
    () =>
      [...(feasibleEstimates || [])]
        .map(estimate => estimate.comfortScore - house.comfortScore)
        .sort((a, b) => b - a),
    [house, feasibleEstimates],
  );

  const getComfortRating = useCallback(
    (estimate: resultsPage_house_estimates) => {
      const highestComfortDelta = comfortDeltas[0];
      const estimateDelta = estimate.comfortScore - house.comfortScore;
      const comfortRating = Math.ceil((estimateDelta / highestComfortDelta) * 5);
      return Math.min(5, Math.max(1, comfortRating)) || 1; // || 1 because could be NaN if highestComf = 0
    },
    [comfortDeltas, house.comfortScore],
  );

  const sortedEstimates = useMemo(
    () => sortEstimates([...house.estimates], infeasibleSolutions, sortProp),
    [infeasibleSolutions, house, sortProp],
  );

  /** Returns whether the current Rc-value for walls, floor or roof is already that of extra insulation.
   * Used to show a flag to indicate the estimate is for an insulation top-up, meaning the savings will be relatively low.
   */
  const estimateIsExtraInsulation = useCallback(
    (solution: Solution, constructionYear: number) => {
      let currentRc = 0;
      switch (solution) {
        case 'wallInsulation':
          currentRc = house.walls.rc!;
          return currentRc >= 2.14 || constructionYear > 1975;
        case 'floorInsulation':
        case 'crawlspaceInsulation':
          currentRc = house.floor.rc!;
          return currentRc >= 1.93 || constructionYear > 1983;
        case 'atticFloorInsulation':
        case 'innerRoofInsulation':
          currentRc = house.roof.rc!;
          return currentRc >= 2 || constructionYear > 1975;
        default:
          return false;
      }
    },
    [house],
  );

  const currentLivingGlazing = useMemo(
    () => getGlazingType(house.windowsZTAU!.livingU!),
    [house.windowsZTAU],
  );
  const currentSleepingGlazing = useMemo(
    () => getGlazingType(house.windowsZTAU!.sleepingU!),
    [house.windowsZTAU],
  );

  const isMounted = useIsMounted();
  const sendGAEvent = useGAEvent();
  const [resultsEventSent, setResultsEventSent] = useCookie('resultsEventSent');
  useEffect(() => {
    if (isMounted() && (!resultsEventSent || resultsEventSent !== house.id)) {
      void sendGAEvent('quick_scan_results', {
        house_id: house.id,
        solution_estimates: parseSolutionEstimatesForGTM(feasibleEstimates),
        non_interaction: true,
      });
      setResultsEventSent(house.id);
    }
  }, [feasibleEstimates, house.id, isMounted, resultsEventSent, sendGAEvent, setResultsEventSent]);

  return (
    <Flex flexWrap="wrap" mx={-2}>
      <Box width={[1, 1, 1, 3 / 4]} mx="auto" mb={[0, 0, 2, 5]}>
        <ResultSorter sortProp={sortProp} setSortProp={setSortProp} />
      </Box>

      <Box width={1} px={[2, 2, 2, 2, 0]}>
        <Flex flexWrap="wrap" style={{ position: 'relative' }} mx={-2}>
          <FlipMove typeName={null}>
            {sortedEstimates.map(estimate => {
              const solution = estimate.solution;
              const isExtraInsulation = estimateIsExtraInsulation(solution, house.constructionYear);
              return (
                <BoxClass
                  key={`result-${solution}`}
                  width={[1, 1, 1 / 2, 1 / 3]}
                  px={2}
                  py={[1, 1, 4]}
                >
                  <ResultCard
                    comfortRating={getComfortRating(estimate)}
                    currentGlazing={{
                      living: currentLivingGlazing,
                      sleeping: currentSleepingGlazing,
                    }}
                    currentEnergyIndex={currentEnergyIndex}
                    emissionRating={getEmissionRating(estimate)}
                    estimate={estimate}
                    hasGreatestImpact={solution === greatestImpact}
                    hasMostComfort={solution === mostComfort}
                    hasQuickestPayback={solution === quickestPayback}
                    hideCheckbox={!!hideCheckboxes}
                    isExtraInsulation={isExtraInsulation}
                    resultCardTexts={resultCardTexts}
                    showEnergyLabelPrediction={showEnergyLabelPrediction}
                    toggleActiveCallback={toggleActiveSolutionsCallback}
                  />
                </BoxClass>
              );
            })}
            {React.Children.map(children, (child, index) => {
              if (!child) return <></>;
              const childProps = (child as ReactElement<{ columns?: 1 | 2 | 3 }>).props;
              return (
                <BoxClass
                  key={`extraCard-${index}`}
                  width={
                    childProps.columns
                      ? [1, 1, 1 / 2, childProps.columns / 3]
                      : [1, 1, 1 / 2, 1 / 3]
                  }
                  px={2}
                  py={[1, 1, 4]}
                >
                  {child}
                </BoxClass>
              );
            })}
          </FlipMove>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ResultCards;
