import { Solution } from '@energiebespaarders/constants';
import centralHeatingBoiler from '/public/img/illustrations/cutouts/cv-ketel.jpg';
import roofInsulation from '/public/img/illustrations/cutouts/dakisolatie.jpg';
import insulationGlazing from '/public/img/illustrations/cutouts/isolatieglas.jpg';
import chargingBox from '/public/img/illustrations/cutouts/laadpaal.png';
import wallInsulation from '/public/img/illustrations/cutouts/spouwmuurisolatie.jpg';
import heating from '/public/img/illustrations/cutouts/verwarming.jpg';
import floorInsulation from '/public/img/illustrations/cutouts/vloerisolatie.jpg';
import pvSystem from '/public/img/illustrations/cutouts/zonnepanelen.jpg';

const solutionCutoutIllustrations: Record<Solution, string> = {
  pvSystem,
  wallInsulation,
  floorInsulation,
  crawlspaceInsulation: floorInsulation,
  innerRoofInsulation: roofInsulation,
  atticFloorInsulation: roofInsulation,
  windowFrames: insulationGlazing,
  windowGlazing: insulationGlazing,
  centralHeatingBoiler,
  hybridHeatPump: heating,
  electricHeatPump: heating,
  airToAirHeatPump: heating,
  underfloorHeating: '',
  chargingBox,
  homeBattery: '',
  miscellaneous: '',
  sedum: '',
};

export default solutionCutoutIllustrations;
